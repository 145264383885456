<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative" style="z-index: 999 !important;">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-wobby-purple-100 bg-opacity-60 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden w-full max-w-2xl mx-auto">
                            <div class="bg-white px-8 py-6 rounded-lg shadow-xl">
                                <div class="relative flex flex-col justify-between space-y-6">
                                    <div class="bg-wobby-purple-100 text-wobby-purple-700 p-6 rounded-lg">
                                        <h1 class="text-4xl font-fakt-medium mb-2">Welcome to Wobby 👋</h1>
                                        <p class="text-xl">We're excited you're here, {{ capitalizedFirstName }}. Let's get you set up!</p>
                                    </div>

                                    <div class="space-y-4">
                                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div class="bg-wobby-white ring-1 ring-gray-300 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                                                <div class="flex items-center gap-2 mb-3">
                                                    <Icon class="h-6 w-6 text-wobby-purple-600" name="heroicons:video-camera" />
                                                    <p class="text-lg font-fakt-medium text-gray-800">Quick overview video</p>
                                                </div>
                                                <iframe class="w-full aspect-video rounded-lg"
                                                    src="https://www.youtube.com/embed/GNYHIbnLaTA"
                                                    title="Getting Started with Wobby" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen></iframe>
                                            </div>
                                            <div class="bg-wobby-white ring-1 ring-gray-300 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                                                <div class="flex items-center gap-2 mb-3">
                                                    <Icon class="h-6 w-6 text-wobby-purple-600" name="heroicons:academic-cap" />
                                                    <p class="text-lg font-fakt-medium text-gray-800">Read the docs</p>
                                                </div>
                                                <a href="https://wobby.gitbook.io/docs/" target="_blank" 
                                                   class="inline-block mt-2 px-4 py-2 bg-wobby-purple-100 text-wobby-purple-700 rounded-md hover:bg-wobby-purple-200 transition-colors">
                                                   Visit the Documentation
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-end items-center space-x-4 mt-6">
                                    <a href="https://calendly.com/d/4wf-jmp-r8r/get-to-know-wobby" target="_blank"
                                       class="px-4 py-2 bg-wobby-purple-600 text-white rounded-md hover:bg-wobby-purple-700 transition-colors">
                                        Book a Demo
                                    </a>
                                    <button type="button" @click="closeModal"
                                        class="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors" 
                                        id="closeWelcome">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, onMounted, ref } from 'vue'

const identityStore = useIdentityStore();

const props = defineProps<{
    show: boolean;
}>();

const router = useRouter();

// Define the emits
const emit = defineEmits(["close"]);

const closeModal = () => {
    emit('close');
};

const capitalizedFirstName = computed(() => {
    const firstName = identityStore.user?.firstName || '';
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
});

</script>



<style scoped>
.v-enter-active {
    transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}</style>