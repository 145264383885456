<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative" style="z-index: 999 !important;">
            <div class="fixed top-0 left-[50%]">
                <ConfettiExplosion :stageHeight="viewportHeight" />
            </div>

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-wobby-purple-100 bg-opacity-60 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden w-full max-w-[600px] mx-auto">
                            <div class="bg-white px-6 py-5 rounded-md">
                                <swiper
                                    ref="swiper"
                                    @swiper="onSwiper"
                                    :effect="'cards'"
                                    :grabCursor="true"
                                    :spaceBetween="30"
                                    class="mySwiper"
                                >
                                    <swiper-slide>
                                        <div class="relative flex flex-col justify-between space-y-2 mb-8 bg-white">
                                            <div class="relative flex items-start justify-center w-full h-72 mb-2 bg-gray-50 border border-gray-100 rounded-md overflow-hidden">
                                                <div class="relative pt-6 w-full max-w-[600px]">
                                                    <img src="@/assets/img/document-blur-bg.png" class="absolute top-[20%] left-0 w-full object-top -mt-4 opacity-[30%]" />
                                                    <img src="@/assets/img/document3d.webp" class="relative w-full object-top -mt-4" />
                                                    <div class="absolute top-[25%] left-[30%] w-[40%]">
                                                        <strong class="block font-fakt-medium text-xs mb-2">Housing market report</strong>
                                                        <div class="opacity-[75%]">
                                                            <USkeleton class="h-3 w-[100%] mb-1.5" />
                                                            <USkeleton class="h-3 w-[100%] mb-1.5" />
                                                            <USkeleton class="h-3 w-[95%] mb-1.5" />
                                                            <USkeleton class="h-3 w-[97%] mb-1.5" />
                                                            <USkeleton class="h-3 w-[85%] mb-1.5" />
                                                            <USkeleton class="h-3 w-[75%] mb-1.5" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
        
                                            <h2 class="text-2xl font-fakt-medium">Wobby Documents is here!</h2>
                                            <p class="text-gray-500">We’re excited to introduce Documents, a <span class="text-gray-800">new feature that brings together everything you need to go from research to content</span>. Documents replace the older Reports and Workspaces features. Let us show you what's new!</p>
                                        </div>
                                    </swiper-slide>
                                    
                                    <swiper-slide>
                                        <div class="relative flex flex-col justify-between space-y-2 mb-8 bg-white">
                                            <div class="relative flex items-start justify-center w-full h-72 mb-2 bg-gray-50 border border-gray-100 rounded-md overflow-hidden">
                                                <img src="@/assets/img/document-blur-bg.png" class="absolute top-[20%] left-0 w-full object-top -mt-4 opacity-[30%]" />
                                                <DotLottieVue ref="animation" class="lottie-view relative pointer-events-none -mt-4" src="/assets/lottie/research-animation.json" :loop="true" :autoplay="true" />
                                            </div>
        
                                            <h2 class="text-2xl font-fakt-medium">Real-time research</h2>
                                            <p class="text-gray-500">
                                                You can now conduct real-time research and pull in <strong class="text-gray-800">data directly from online sources.</strong>
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    
                                    <swiper-slide>
                                        <div class="relative flex flex-col justify-between space-y-2 mb-8 bg-white">
                                            <div class="relative flex items-start justify-center w-full h-72 mb-2 bg-gray-50 border border-gray-100 rounded-md overflow-hidden">
                                                <img src="@/assets/img/document-blur-bg.png" class="absolute top-[20%] left-0 w-full object-top -mt-4 opacity-[30%]" />
                                                <DotLottieVue ref="animation" class="lottie-view relative pointer-events-none" src="/assets/lottie/rewrite-animation.json" :loop="true" :autoplay="true" />
                                            </div>
        
                                            <h2 class="text-2xl font-fakt-medium">Text improvement tools</h2>
                                            <p class="text-gray-500">
                                                You can now <strong class="text-gray-800">rewrite, simplify, expand, or translate</strong> text directly within your document. With just one click, you can fix grammar, shorten or lengthen paragraphs, or even translate text into different languages, making content creation faster and more intuitive.
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    
                                    <swiper-slide>
                                        <div class="relative flex flex-col justify-between space-y-2 mb-8 bg-white">
                                            <div class="relative flex items-start justify-center w-full h-72 mb-2 bg-gray-50 border border-gray-100 rounded-md overflow-hidden">
                                                <div class="relative pt-6 w-full max-w-[600px]">
                                                    <img src="@/assets/img/document-blur-bg.png" class="absolute top-[20%] left-0 w-full object-top -mt-4 opacity-[30%]" />
                                                    <img src="@/assets/img/document3d-share.webp" class="relative w-full object-top -mt-4" />
                                                </div>
                                            </div>
        
                                            <h2 class="text-2xl font-fakt-medium">Smart chat</h2>
                                            <p class="text-gray-500">
                                                With the smart chat you can <span class="text-gray-800">ask questions</span>, <span class="text-gray-800">create charts</span>, <span class="text-gray-800">summarize documents</span> and more. The chat has all the context of your document, giving you the answers you need.  
                                            </p>
                                        </div>
                                    </swiper-slide>
                                </swiper>


                                <div class="flex items-center justify-between w-ful gap-3">
                                    <button type="button" @click="closeModal" v-if="activeIndex == 0"
                                        class="rounded-md px-3 py-2 bg-white text-sm  font-fakt-medium text-gray-900 border border-gray-300 shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-gray-50 focus:z-10 box-sizing:border-box" id="closeWelcome">Close</button>
                                    <button type="button" @click="swiperPrevSlide" v-else
                                        class="rounded-md px-3 py-2 bg-white text-sm  font-fakt-medium text-gray-900 border border-gray-300 shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-gray-50 focus:z-10 box-sizing:border-box" id="closeWelcome">Back</button>

                                    <div class="absolute left-[50%] -translate-x-[50%] flex gap-1">
                                        <button class="w-2 h-2 rounded-full" :class="activeIndex == 0 ? 'bg-wobby-purple-400' : 'bg-wobby-purple-200'" @click="swiperSlideTo(0)"></button>
                                        <button class="w-2 h-2 rounded-full" :class="activeIndex == 1 ? 'bg-wobby-purple-400' : 'bg-wobby-purple-200'" @click="swiperSlideTo(1)"></button>
                                        <button class="w-2 h-2 rounded-full" :class="activeIndex == 2 ? 'bg-wobby-purple-400' : 'bg-wobby-purple-200'" @click="swiperSlideTo(2)"></button>
                                        <button class="w-2 h-2 rounded-full" :class="activeIndex == 3 ? 'bg-wobby-purple-400' : 'bg-wobby-purple-200'" @click="swiperSlideTo(3)"></button>
                                    </div>

                                    <button type="button" @click="swiperNextSlide" v-if="activeIndex == 0"
                                        class="rounded-md px-3 py-2 bg-wobby-purple-400 text-sm  font-fakt-medium text-white shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-wobby-purple-500 focus:z-10 box-sizing:border-box" id="closeWelcome">Explore</button>
                                    <button type="button" @click="swiperNextSlide" v-else-if="activeIndex != 3"
                                        class="rounded-md px-3 py-2 bg-wobby-purple-400 text-sm  font-fakt-medium text-white shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-wobby-purple-500 focus:z-10 box-sizing:border-box" id="closeWelcome">Next</button>
                                    <div class="flex gap-2" v-else>
                                        <a href="https://calendly.com/d/4wf-jmp-r8r/get-to-know-wobby" target="_blank">
                                            <button type="button"
                                                class="rounded-md px-3 py-2 bg-white text-sm  font-fakt-medium text-gray-900 border border-gray-300 shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-gray-50 focus:z-10 box-sizing:border-box" id="closeWelcome">Book a demo</button>
                                        </a>
                                        <button type="button" @click="closeModal"
                                            class="rounded-md px-3 py-2 bg-wobby-purple-400 text-sm font-fakt-medium text-white shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-wobby-purple-500 focus:z-10 box-sizing:border-box" id="closeWelcome">Close</button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
import ConfettiExplosion from "vue-confetti-explosion";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';

const viewportHeight = window?.innerHeight || 0;

const props = defineProps<{
    show: boolean;
}>();

const router = useRouter();

// Define the emits
const emit = defineEmits(["close"]);

const closeModal = () => {
    emit('close');
};

const swiperInstance = ref()
const activeIndex = ref(0)
function onSwiper(swiper: any) {
    swiperInstance.value = swiper
    
    swiperInstance.value.on('slideChange', (val: any) => {
        activeIndex.value = val.activeIndex
    })
}
const swiperNextSlide = () => {
    swiperInstance.value.slideNext()
};
const swiperPrevSlide = () => {
    swiperInstance.value.slidePrev()
};
const swiperSlideTo = (index: number) => {
    swiperInstance.value.slideTo(index)
};
</script>



<style scoped>
.v-enter-active {
    transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.lottie-view {
    display: block;
    --width: 1000px;
    --height: 420px;

    width: var(--width);
    min-width: var(--width);
    max-width: var(--width);

    height: var(--height);
    min-height: var(--height);
    max-height: var(--height);
}
</style>