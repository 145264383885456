<template>
    <div v-if="!isMobile">
        <Banner v-if="banner.show" />
        <div class="relative flex w-full">
            <Sidebar :is-open-sidebar="sidebarOpen" @toggle-sidebar="toggleSidebar" />
            <div :class="`w-full transition-all duration-300 ${sidebarOpen ? 'pl-[200px] lg:pl-[264px]' : 'pl-[110px]'}`">
                <main class="pt-2">
                    <div class="px-2 min-h-[87vh] py-8">
                        <main>
                            <slot></slot>
                        </main>
                    </div>
                </main>
                <footer v-show="showFooter" class="relative flex rounded-md pb-8 mx-8 mb-1 mt-3">
                    <div class="flex w-full pt-6 px-6 rounded-md">
                        <div class="flex text-sm text-gray-400 justify-end items-center gap-2">
                            <a class=" hover:text-gray-800" href="https://wobby.ai?utm_source=app" target="_blank">©
                                Copyright
                                Wobby BV {{ date }}</a>
                            |
                            <a class="hover:text-gray-800" href="https://community.wobby.ai/" target="_blank">Community</a>
                            <a class="hover:text-gray-800" href="https://wobby.gitbook.io/docs/" target="_blank">Guide</a>
                            <a class="hover:text-gray-800" href="https://wobby.ai/contact/" target="_blank">Contact us</a>
                        </div>

                        <div class="flex-grow"></div>

                        <div class="flex text-sm  text-gray-400 justify-end items-center gap-2">
                            <a class="hover:text-gray-800" href="https://wobby.ai/terms-and-conditions/"
                                target="_blank">Terms
                                of Use</a>
                            <a class="hover:text-gray-800" href="https://wobby.ai/privacy-policy/" target="_blank">Privacy
                                Policy</a>
                            <!-- <a target="_blank">Cookie Policy</a>-->
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="p-8">
            <img class="-ml-5 -mb-2 h-20" src="/assets/wobby-logo/logo_purple.svg" />
            <div class="my-5">
                <h1 class="font-fakt-medium text-lg text-gray-800">Oops! Wobby platform isn't optimized for mobile yet.
                </h1>
                <p class="mt-1 text-gray-800">Please try accessing it from a desktop or laptop for the
                    best
                    experience.<br> Thanks for your understanding!</p>
            </div>
            <a class=" bg-gray-50 hover:bg-gray-100 ring-1 p-2 text-gray-500 ring-wobby-purple rounded-lg"
                href="https://wobby.ai">Back to Homepage</a>
        </div>
        <footer class="mt-5 bg-gray-50 w-full flex py-5 px-8">
            <div class="flex w-full rounded-md">
                <div class="flex text-sm text-gray-400 justify-end items-center gap-2">
                    <a class=" hover:text-gray-800" href="https://wobby.ai?utm_source=app" target="_blank">© Copyright
                        Wobby
                        BV {{ date }}</a>

                </div>

                <div class="flex-grow"></div>

                <div class="flex text-sm  text-gray-400 justify-end items-center gap-2">
                    <a class="hover:text-gray-800" href="https://wobby.ai/terms-and-conditions/" target="_blank">Terms
                        of
                        Use</a>
                    <a class="hover:text-gray-800" href="https://wobby.ai/privacy-policy/" target="_blank">Privacy
                        Policy</a>
                    <!-- <a target="_blank">Cookie Policy</a>-->
                </div>
            </div>
        </footer>
    </div>

    <HelpSupportSlideover />
    <InviteMembersSlideover />

    <WelcomeDialog :show="welcomeDialogShownCookie !== 'seen'" @close="handleWelcomeDialogClose" />
    <DocumentAnnouncementDialog 
        :show="showDocumentAnnouncementDialog" 
        @close="handleDocumentAnnouncementDialogClose" 
    />

<CommandPalette />
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import Sidebar from '../components/sidebar/Sidebar.vue';
import WelcomeDialog from '~/components/dialogs/WelcomeDialog.vue';
import DocumentAnnouncementDialog from '~/components/dialogs/DocumentAnnouncementDialog.vue';

const { banner } = storeToRefs(useAppStore());
const date = new Date().getFullYear();

// WELCOME DIALOG
const welcomeDialogShownCookie = useCookie('welcomeDialogShown', {
    expires: new Date(9999, 12, 31),
});

// DOCUMENT ANNOUNCEMENT DIALOG
const documentAnnouncementDialogShownCookie = useCookie('documentAnnouncementDialogShown', {
    expires: new Date(9999, 12, 31),
});

const sidebarOpen = ref(false);
const toggleSidebar = (value?: boolean) => {
    sidebarOpen.value = value !== undefined ? value : !sidebarOpen.value;
    localStorage.setItem('sidebarOpen', sidebarOpen.value.toString());
};

let isMobile = ref(false)

const mobileBreakpoint = 768; // Define mobile breakpoint
const route = useRoute();
const showFooter = computed(() => {
    return !route.fullPath.includes('/workspaces/chat/') && !route.fullPath.includes('/knowledge/submit-data') && !route.fullPath.includes('/search?result');
});

const updateDeviceType = () => {
    isMobile.value = window.innerWidth <= mobileBreakpoint;
};

onMounted(() => {
    updateDeviceType(); // Check on mount
    window.addEventListener('resize', updateDeviceType); // Add resize listener

    if (localStorage.getItem('sidebarOpen') === 'true') {
        sidebarOpen.value = true;
    }
});

onUnmounted(() => {
    window.removeEventListener('resize', updateDeviceType); // Clean up listener
});

const showDocumentAnnouncementDialog = ref(false);

// Function to check and show the document announcement dialog
const checkAndShowDocumentAnnouncement = () => {
    showDocumentAnnouncementDialog.value = 
        welcomeDialogShownCookie.value === 'seen' && 
        documentAnnouncementDialogShownCookie.value !== 'seen';
};

// Function to handle welcome dialog close
const handleWelcomeDialogClose = () => {
    welcomeDialogShownCookie.value = 'seen';
    setTimeout(() => {
        checkAndShowDocumentAnnouncement();
    }, 500);
};

// Function to handle document announcement dialog close
const handleDocumentAnnouncementDialogClose = () => {
    documentAnnouncementDialogShownCookie.value = 'seen';
    showDocumentAnnouncementDialog.value = false;
};

// Check for document announcement dialog on component mount
onMounted(() => {
    checkAndShowDocumentAnnouncement();
});

</script>

<style>
.sidebar,
.navigation-menu {
    width: 64px;
}

html {
    background-color: #f9fafb !important;
    scrollbar-gutter: stable;
}

.button-toggle {
    left: 50px;
    transition: all 0.3s ease-in-out;
}

.button-toggle.active {
    left: 250px;
}

/* Base state of the sidebar */
.sidebar {
    width: 64px;
    /* collapsed width */
    transition: width 0.2s ease-in-out;
}

/* Active state of the sidebar */
.sidebar.active {
    width: 205px;
    /* expanded width */
}
</style>
